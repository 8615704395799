<template>
  <v-card width="256" flat>
    <v-navigation-drawer permanent floating color="grey">
      <v-list nav class="pa-0">
        <router-link to="home" v-slot="{ href, route, navigate, isActive, isExactActive }">
          <v-list-item class="ma-0 active" @click="goToPage('home')" v-if="isActive">
            <heroicon name="widgetBold" fill="#EE1D23" class="mr-2 ml-1" heigh="20" width="35"/>
            <span class="active-title ">Главная</span>
          </v-list-item>
          <v-list-item to="home" @click="goToPage('home')" class="ma-0" v-else>
            <heroicon name="widgetBold" fill="#666666" class="mr-2 ml-1" heigh="20" width="35"/>
            <span >Главная</span>
          </v-list-item>
        </router-link>
        <template v-for="(screen, i) in menus">
          <router-link v-for="(view, j) in screen.permissions" :key="`view-${view.description}`" :to="view.view"
                       v-slot="{ href, route, navigate, isActive, isExactActive }">
            <v-list-item class="ma-0 active" @click="goToPage(view.view)" v-if="isActive">
              <v-icon color="#EE1D23" class="mr-3 ml-2">{{view.icon}}</v-icon>
              <v-list-item-title class="active-title">{{ view.description }}</v-list-item-title>
            </v-list-item>
            <v-list-item class="ma-0" @click="goToPage(view.view)" v-else>
              <v-icon color="#666666" class="mr-3 ml-2">{{view.icon}}</v-icon>
              <v-list-item-title>{{ view.description }}</v-list-item-title>
            </v-list-item>
          </router-link>
        </template>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "app-drawer",
  components: {
    VuePerfectScrollbar,
  },
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
    drawWidth: {
      type: [Number, String],
      default: '350',
    },
    value: Boolean,
  },
  data: () => ({
    mini: false,
    menus: null,
    scrollSettings: {
      maxScrollbarLength: 160
    }
  }),
  computed: {
    active() {
      return views => !!views.find(v => ('/' + v.view) === this.$route.path)
    },
  },

  methods: {
    goToPage(page) {
      this.$router.push("/" + page)
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.$http
          .get("/menu")
          .then(response => {
                this.menus = response.data;
              },
              e => this.$store.dispatch('setGlobalErrorMessage', e.response.data))
    }
  }
};
</script>

<style>
.active {
  background: #EE1D231F;
}

.active-title {
  color: #EE1D23;
}
</style>
