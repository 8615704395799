import http from "../plugins/http";
import store from "../store";

export default [
  {
    path: "*",
    meta: {
      public: true
    },
    redirect: {
      path: "/404"
    }
  },
  {
    path: "/404",
    meta: {
      public: true
    },
    name: "NotFound",
    component: () => import(`@/views/error/NotFound.vue`)
  },
  {
    path: "/403",
    meta: {public: true},
    name: "AccessDenied",
    component: () => import(`@/views/error/Deny.vue`)
  },
  {
    path: "/500",
    meta: {public: true},
    name: "ServerError",
    component: () => import(`@/views/error/Error.vue`)
  },
  {
    path: "/login",
    meta: {public: true},
    name: "Login",
    component: () => import(`@/views/Login.vue`)
  },
  {
    path: "/password/restore/:secret",
    meta: {public: true},
    name: "restorePassword",
    component: () => import(`@/views/RestorePassword.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.secret) {
        http.get(`/verify/password/restore?secret=${routeTo.params.secret}`)
          .then(r => {
              next()
              store.dispatch('setPasswordRestore', {
                user: r.data,
                secret: routeTo.params.secret
              })
            },
            () => next({name: 'NotFound'})
          )
          .catch(() => next({name: 'NotFound'}))
      } else {
        next({name: 'NotFound'})
      }
    },
  },
  {
    path: "/",
    meta: {},
    name: "Root",
    redirect: {
      name: "Home"
    }
  },
  {
    path: "/home",
    meta: {breadcrumb: true},
    name: "Home",
    component: () => import(`@/views/Home.vue`)
  },
  {
    path: "/permission",
    meta: {breadcrumb: true},
    name: "permission",
    component: () =>
      import(
        `@/views/Permission.vue`)
  },
  {
    path: "/lang",
    meta: {breadcrumb: true},
    name: "lang",
    component: () =>
      import(
        `@/views/Lang.vue`)
  },
  {
    path: "/events",
    meta: {breadcrumb: true},
    name: "events",
    component: () =>
      import(
        `@/views/Events.vue`)
  },
  {
    path: "/tariff",
    meta: {breadcrumb: true},
    name: "tariff",
    component: () =>
      import(
        `@/views/Tariff.vue`)
  },
  {
    path: "/invoices",
    meta: {breadcrumb: true},
    name: "invoices",
    component: () =>
      import(
        `@/views/Invoices.vue`)
  },
  {
    path: "/participant-category",
    meta: {breadcrumb: true},
    name: "participantCategory",
    component: () =>
      import(
        `@/views/ParticipantCategory.vue`)
  },
  {
    path: "/roles",
    meta: {breadcrumb: true},
    name: "roles",
    component: () =>
      import(
        `@/views/Roles.vue`)
  },
  {
    path: "/users",
    meta: {breadcrumb: true},
    name: "users",
    component: () =>
      import(
        `@/views/Users/index.vue`)
  },
  {
    path: "/citizenship-dict",
    meta: {breadcrumb: true},
    name: "citizenshipDict",
    component: () =>
      import(
        `@/views/Citizenship.vue`)
  },
  {
    path: "/user/:id/edit",
    meta: {breadcrumb: true},
    name: "user-edit",
    component: () =>
      import(
        `@/views/User/Edit.vue`)
  },
  {
    path: "/teams",
    meta: {breadcrumb: true},
    name: "teams",
    component: () =>
      import(
        `@/views/Teams.vue`)
  },
  {
    path: "/tourists",
    meta: {public: true},
    name: "tourists",
    component: () =>
      import(
        `@/views/Tourists.vue`)
  },
  {
    path: "/members",
    meta: {breadcrumb: true},
    name: "members",
    component: () =>
      import(
        `@/views/Members.vue`)
  },
  {
    path: "/team/:id",
    meta: {public: true},
    name: "teamByTeamId",
    component: () =>
      import(`@/views/VehicleInfo/index.vue`),
  },
  {
    path: "/add-vehicle/:type/:id",
    meta: {public: true},
    name: "classic",
    component: () => import(`@/views/AddVehicles/TypeVehicles.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.type && !!routeTo.params.id) {
        http.get(`/team/check/link?link=${routeTo.params.type}`)
          .then(r => {
              next()
            },
            () => next({name: 'NotFound'})
          )
          .catch(() => next({name: 'NotFound'}))

      }
    },
  },
  {
    path: "/add-vehicles/technical-data/:id",
    meta: {public: true},
    name: "technical-data",
    component: () => import(`@/views/AddVehicles/TechnicalData.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.id) {
        http.get(`/vehicles/byId?id=${routeTo.params.id}`)
          .then(r => {
              store.dispatch('setCurrentVehicle', r.data)
              next()
            }, () => next({name: 'NotFound'})
          )
          .catch(() => next({name: 'NotFound'}))

      }
    },
  },
  {
    path: "/add-vehicles/sports-documents/:id",
    meta: {public: true},
    name: "sports-documents",
    component: () => import(`@/views/AddVehicles/SportsDocuments.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.id) {
        http.get(`/vehicles/byId?id=${routeTo.params.id}`)
          .then(r => {
              store.dispatch('setCurrentVehicle', r.data)
              next()
            }, () => next({name: 'NotFound'})
          )
          .catch(() => next({name: 'NotFound'}))

      }
    },
  },
  {
    path: "/add-vehicles/passport-data/:id",
    meta: {public: true},
    name: "passport-data",
    component: () => import(`@/views/AddVehicles/PassportData.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.id) {
        http.get(`/vehicles/byId?id=${routeTo.params.id}`)
          .then(r => {
              store.dispatch('setCurrentVehicle', r.data)
              next()
            }, () => next({name: 'NotFound'})
          )
          .catch(() => next({name: 'NotFound'}))

      }
    },
  },
  {
    path: "/add-vehicles/vehicle-photos/:id",
    meta: {public: true},
    name: "vehicle-photos",
    component: () => import(`@/views/AddVehicles/VehiclePhotos.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.id) {
        http.get(`/vehicles/byId?id=${routeTo.params.id}`)
          .then(r => {
              store.dispatch('setCurrentVehicle', r.data)
              next()
            }, () => next({name: 'NotFound'})
          )
          .catch(() => next({name: 'NotFound'}))

      }
    },
  },
  {
    path: "/add-vehicles/trailer-data/:id",
    meta: {public: true},
    name: "trailer-data",
    component: () => import(`@/views/AddVehicles/TrailerData.vue`),
    beforeEnter(routeTo, _routeFrom, next) {
      if (!!routeTo.params.id) {
        http.get(`/vehicles/byId?id=${routeTo.params.id}`)
          .then(r => {
              store.dispatch('setCurrentVehicle', r.data)
              next()
            }, () => next({name: 'NotFound'})
          )
          .catch(() => next({name: 'NotFound'}))

      }
    },
  },
  {
    path: "/vehicle-sub-category",
    meta: {breadcrumb: true},
    name: "vehicle-sub-category",
    component: () =>
      import(
        `@/views/dict/VehicleSubCategory.vue`)
  },
  {
    path: "/vehicle-category",
    meta: {breadcrumb: true},
    name: "vehicle-category",
    component: () =>
      import(
        `@/views/dict/VehicleCategory.vue`)
  },
  {
    path: "/vehicle-type",
    meta: {breadcrumb: true},
    name: "vehicle-type",
    component: () =>
      import(
        `@/views/dict/VehicleTypeDict.vue`)
  },
  {
    path: "/gas",
    meta: {breadcrumb: true},
    name: "gas",
    component: () =>
      import(
        `@/views/dict/Gas.vue`)
  },
  {
    path: "/stage",
    meta: {breadcrumb: true},
    name: "stageCategory",
    component: () =>
      import(
        `@/views/dict/StageCategoty.vue`)
  },
  {
    path: "/media-type",
    meta: {breadcrumb: true},
    name: "mediaType",
    component: () =>
      import(
        `@/views/dict/MediaType.vue`)
  },
  {
    path: "/media-job-type",
    meta: {breadcrumb: true},
    name: "mediaJobType",
    component: () =>
      import(
        `@/views/dict/MediaJobType.vue`)
  },
  {
    path: "/cargo-types",
    meta: {breadcrumb: true},
    name: "cargo-types",
    component: () =>
      import(
        `@/views/dict/CargoTypesDict.vue`)
  },
  {
    path: "/media",
    meta: {breadcrumb: true},
    name: "media",
    component: () =>
      import(
        `@/views/Media.vue`)
  },
  {
    path: "/accounting",
    meta: {public: true},
    name: "accounting",
    component: () =>
      import(
        `@/views/Accounting/index.vue`)
  },
  {
    path: "/tariff-info",
    meta: {breadcrumb: true},
    name: "tariff-info",
    component: () =>
      import(
        `@/views/TariffInfo.vue`)
  },
];
