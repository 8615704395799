import Vue from "vue";
import Vuetify from "vuetify/lib";
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    // dark: localStorage.getItem('dark-theme') === 'false', // getItem возвращает строку,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#EE1D23",
        secondary: "",
        accent: "#EE1D23",
        error: "#FF5252",
        red: "#FA4747",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        white: "#000",
        grey: "#fafafa"
      },
    }
  }
});
