<template>
  <div class="d-flex align-center justify-center" style="height: 100vh">
    <div class="loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style lang="scss">

$base-duration: 500ms;

// Breakpoints
$sm: new-breakpoint(min-width 320px);
$med: new-breakpoint(min-width 768px);
$lg: new-breakpoint(min-width 1024px);

*, *:before, *:after {
  box-sizing: border-box;
  outline: none;
}

html {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-smooth: auto;
  font-weight: 300;
  line-height: 1.5;
  color: #444;
}

body {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  // background-color:darken($color-1, 4%);
}

.loader {
  position: relative;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 2px;
  width: 100px;
  height: 100px;

  > div {
    position: relative;
    width: 100%;
    height: 100%;
    background: #EE1D23;
    transform: scale(0.0);
    transform-origin: center center;
    animation: loader 2s infinite linear;

    &:nth-of-type(7) {}

    &:nth-of-type(1),
    &:nth-of-type(5),
    &:nth-of-type(9) {
      animation-delay: 0.4s;
    }

    &:nth-of-type(4),
    &:nth-of-type(8) {
      animation-delay: 0.2s;
    }

    &:nth-of-type(2),
    &:nth-of-type(6) {
      animation-delay: 0.6s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.8s;
    }
  }
}

@keyframes loader {
  0%   { transform: scale(0.0); }
  40%  { transform: scale(1.0); }
  80%  { transform: scale(1.0); }
  100% { transform: scale(0.0); }
}
</style>