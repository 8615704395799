import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import router from "./router/";
import http from "./plugins/http";
import phoneCodes from "@/api/phoneCodes";
import rules from "@/api/rules";
import {Ripple} from 'vuetify/lib/directives'
import {mapGetters} from "vuex";
import getUnicodeFlagIcon from 'country-flag-icons/unicode';


import "./plugins/vuetify";
import "./components/components"
import "./theme/default.sass";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/scss/style.scss";
import {countries, hasFlag} from 'country-flag-icons';

import HeroIcon from 'vue-heroicons'
import {
    billOutlined,
    checkCircleOutlined,
    closeCircleOutlined,
    dialogOutlined,
    dollarOutlined,
    messageConversationOutlined,
    penOutlined,
    printerOutlined,
    searchOutlined,
    signpostOutlined,
    smartphoneOutlined,
    trashOutlined,
    uploadOutlined,
    userOutlined,
    winRarOutlined,
    downloadOutlined,
    fileLoadOutlined,
    arrowRightOutlined,
    fileRemoveOutlined,
    suspensionOutlined,
    clockOutlined,
    arrowsStrokeOutlined,
    altArrowRightOutlined,
    calendarOutlined,
    usersGroupTwoOutlined,
    minusOutlined,
    plusOutlined,
    bedOutlined,
    fuelOutlined,
    shieldStarOutlined,
    shieldUserOutlined,
    shieldUpOutlined,
    routingOutlined,
    cardOutlined,
    fileTextOutlined,
    busOutlined,

    addCircleBold,
    altArrowDownOutline,
    arrowRightDownBold,
    arrowRightUpBold,
    busBold,
    chartBold,
    chatOutline,
    cityBold,
    crossBold,
    garageBold,
    globusBold,
    headPhonesBold,
    inboxOutBold,
    layerBold,
    lockKeyholeUnlockedBold,
    mapArrowRotateBold,
    passwordBold,
    penBold,
    pointOnMapPerspectiveBold,
    qrCodeBold,
    radarBold,
    serverMinimalisticBold,
    squareTopUpBold,
    threeSquaresBold,
    transferHorizontalOutline,
    trashBold,
    userBold,
    usersGroupTwoRoundedBold,
    walletBold,
    widgetBold,
    checkCircleBold,
    fileTextBold,
    arrowLeftOutlined,
    permission,
    citizenship,
    vehicleCategory,
    participantCategory,
    teams,
    vehicleSubCategory, vehicleType,


} from './icons/icons'

HeroIcon.add([
    altArrowDownOutline,
    transferHorizontalOutline,
    chatOutline,
    searchOutlined,
    messageConversationOutlined,
    winRarOutlined,
    dialogOutlined,
    smartphoneOutlined,
    uploadOutlined,
    userOutlined,
    signpostOutlined,
    billOutlined,
    dollarOutlined,
    penOutlined,
    printerOutlined,
    checkCircleOutlined,
    closeCircleOutlined,
    trashOutlined,
    downloadOutlined,
    fileLoadOutlined,
    arrowRightOutlined,
    fileRemoveOutlined,
    suspensionOutlined,
    busOutlined,
    clockOutlined,
    arrowsStrokeOutlined,
    altArrowRightOutlined,
    calendarOutlined,
    usersGroupTwoOutlined,
    minusOutlined,
    plusOutlined,
    bedOutlined,
    fuelOutlined,
    shieldStarOutlined,
    shieldUserOutlined,
    shieldUpOutlined,
    routingOutlined,
    cardOutlined,
    fileTextOutlined,

    squareTopUpBold,
    inboxOutBold,
    usersGroupTwoRoundedBold,
    penBold,
    walletBold,
    chartBold,
    passwordBold,
    trashBold,
    lockKeyholeUnlockedBold,
    widgetBold,
    qrCodeBold,
    addCircleBold,
    arrowRightDownBold,
    arrowRightUpBold,
    busBold,
    userBold,
    mapArrowRotateBold,
    radarBold,
    headPhonesBold,
    garageBold,
    globusBold,
    pointOnMapPerspectiveBold,
    cityBold,
    layerBold,
    threeSquaresBold,
    serverMinimalisticBold,
    crossBold,
    checkCircleBold,
    fileTextBold,
    arrowLeftOutlined,
    permission,citizenship,vehicleCategory,participantCategory,teams,vehicleSubCategory,vehicleType
])
Vue.use(HeroIcon)

Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$rule = rules;
Vue.prototype.$phoneCodes = phoneCodes;
Vue.prototype.$countries = countries;
Vue.prototype.$hasFlag = hasFlag;
Vue.prototype.$getUnicodeFlagIcon = getUnicodeFlagIcon;


window.dbg = (data) => {
    console.debug(data)
    return data;
}

const token = localStorage.getItem("token");
if (token) http.defaults.headers.common["Authorization"] = "Bearer " + token;


new Vue({
    router,
    store,
    vuetify,
    mapGetters,
    directives: {
        Ripple,
    },
    render: h => h(App)
}).$mount("#app");






