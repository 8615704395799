const Rules = {
  nospace: v => (v || "").indexOf(" ") < 0 || "Пробелы не допустимы",
  required: v => !!v || "Обязательное поле",
  // date: v => /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(v) || "Неверный формат даты",
  date: v => /^\d{4}[\-\/\s]?((((0[13578])|(1[02]))[\-\/\s]?(([0-2][0-9])|(3[01])))|(((0[469])|(11))[\-\/\s]?(([0-2][0-9])|(30)))|(02[\-\/\s]?[0-2][0-9]))$/.test(v) || "Неверный формат даты",
  dateOfBirthMoreThan18: v => v.split("-").length === 3 && new Date().getFullYear() - v.split("-")[0] > 18 || "Участник не может быть младше 18 лет",
  idRequired: v => !!v?.id || "Объект не выбран",
  min: v => !!v && (v || "").length >= 8 || "Минимум 8 символов",
  max: v => !!v && (v || "").length <= 20 || "Максимум 20 символов",
  email: v => !!v && v.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null || 'Некорректный email',
  cyrillic: v => v && !!v.match(/^[\u0400-\u04FF]+$/) || 'Только кириллица',
  latAndNum: v => v && !!v.match(/^[a-zA-Z0-9-_@]*$/) || 'Только латинские буквы и цифры',
  latAndNumAndSpace: v => v && !!v.match(/^[a-zA-Z0-9-\s]*$/) || 'Только латинские буквы и цифры',
  latLettersAndSpace: v => v && !!v.match(/^[a-zA-Z-\s]*$/) || 'Только латинские буквы',
  cyrillicAndSpace: v => v && !!v.match(/^[а-яА-ЯёЁ\s-]+$/) || 'Только кириллица',
  latLetters: v => v && !!v.match(/^[a-zA-Z]+$/) || 'Только латинские буквы',
  lat: v => v && !!v.match(/^[a-zA-Z]*$/) || 'Только латинские буквы',
  latAndCyr: v => v && !!v.match(/^[a-zA-ZА-ЯЁа-яё-]*$/) || 'Только буквы',
  vehicleNumber: v => v && !!v.match(/^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/) || 'Неверный формат номера машины',
  vinNumber: v => !!v && (v || "").length === 17 || "Не верный формат VIN",
  phone: v => v && /(\d{9})$/.test(v) || 'Неверный формат телефона',
  numberWithPoint: v => /^[0-9]+(\.[0-9]+)?$/.test(v) || "Только цифр",
  number: v => /^[0-9]+$/.test(v) || "Только цифры",
  requiredArray: value => !!value && value.length > 0 || "Обязательное поле",
  positiveValue: v => !!v && v > 0 || "Неверный формат. Число должно быть положительным",
  nonNegative: v => v !== null && v !== undefined && v >= 0 || "Число должно быть больше или равно нулю",
  dateBeforeAnother: (dateBefore, dateAfter, message) => !!dateBefore && !!dateAfter && (dateBefore < dateAfter) || message,
  dateAfterToday: (date, message) => date.length === 10 && (new Date(date.split(".")[2] + "-" + date.split(".")[1] + "-" + date.split(".")[0]) > new Date() || new Date(date) === new Date())
    || (message || "Дата должна превышать сегодняшний день"),
  dateBeforeToday: (date, message) => date.length === 10 && (new Date(date.split(".")[2] + "-" + date.split(".")[1] + "-" + date.split(".")[0]) < new Date() || new Date(date) === new Date())
    || (message || "Дата должна ссылаться на сегодня или ранее"),
  taxNumber: s => s.length === 14 || `Требуется 14 символов, ${s.length < 14 ? 'осталось: ' + (14 - s.length) : 'лишние: ' + (s.length - 14)}`,
  login: value => !!value || "Введите логин",
  password: v => v && !!v.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/) || "Слабый пароль",
  maxNumber: (value, max, message) => !max || Number(value) <= Number(max)
    || message || `Указанное число превышает максимальное допустимое ${'(' + max + ')'}`,
  minNumber: (value, min, message) => !min || Number(value) >= Number(min)
    || message || `Указанное число меньше минимального допустимого ${'(' + min + ')'}`,
  minMaxLimit: (value, min, max) => (value <= max && value >= min) || "Значение должно быть в диапазоне от " + min + " до " + max,
  dateBetweenMontesFromAndTo: (dateFrom, date, monthFrom, monthTo) =>
    new Date(date).getTime() >= new Date(dateFrom).setMonth(new Date(dateFrom).getMonth() + monthFrom)
    && new Date(date).getTime() <= new Date(dateFrom).setMonth(new Date(dateFrom).getMonth() + monthTo)
    || "Значение должно быть в диапазоне от " + monthFrom + " до " + monthTo + " месяцев от текущей даты ",
};

export default Rules