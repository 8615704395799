<template>
  <v-app-bar app height="74" flat outlined>
    <div class="page-wrapper d-flex align-center">
      <div>
        <v-img src="/static/images/logo.svg" height="50px" width="100px" contain/>
      </div>
      <v-spacer/>
      {{ username }}
      <v-btn class="ml-5" color="red" text @click="logout">
        Выйти
      </v-btn>
    </div>
  </v-app-bar>
</template>
<script>
import {mapGetters, mapActions, mapState} from "vuex";

export default {
  name: "AppToolbar",
  components: {},
  data() {
    return {
      items: [],
      model: {},
      search: ""
    };
  },
  computed: {
    ...mapGetters(['username', 'isLoggedIn']),
    ...mapGetters(['user']),
    ...mapActions(['setGlobalErrorMessage']),
    ...mapState(['currentBranch']),

  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    changeTheme() {
      localStorage.setItem('dark-theme', this.$vuetify.theme.dark = !this.$vuetify.theme.dark)
    },
  },
  created() {
  },
};
</script>
<style>
.text-overflow {
  white-space: nowrap;
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis;
  width: 120px;
  color: #EE1D23;
  font-size: 20px;
  padding: 0 10px;
}

.role-text {
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
}

.role-font-size {
  font-size: 16px;
  line-height: 20px;
}
</style>

